import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Parser from "html-react-parser";
import { useCookies } from "react-cookie";
import { Button, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useState } from "react";
import axios from "axios";
import ConnectedTvRoundedIcon from "@mui/icons-material/ConnectedTvRounded";
import WifiCalling3RoundedIcon from "@mui/icons-material/WifiCalling3Rounded";
import WifiRoundedIcon from "@mui/icons-material/WifiRounded";
import RouterRoundedIcon from "@mui/icons-material/RouterRounded";
import { axiosPost, axiosGet } from "../../common/services/axios/apiCalls";
import { Typography, TextField } from "@mui/material";
import {
  ValidarRut,
  FormatearRut,
  ValidarFormatoRut,
} from "../../common/styles/js/common/custom-kiwox.js";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ImeiModal from "./imeiModal.js";

const iconsArray = {
  ConnectedTvRoundedIcon: <ConnectedTvRoundedIcon />,
  WifiCalling3RoundedIcon: <WifiCalling3RoundedIcon />,
  WifiRoundedIcon: <WifiRoundedIcon />,
  RouterRoundedIcon: <RouterRoundedIcon />,
};

function InsuranceForm({
  onResult,
  setOffer,
  imei,
  brand,
  value,
  client_name,
  model,
  rut,
  client_email,
  setRut,
  setClientEmail,
  setIsEmailValid,
  isEmailValid,
  identificationNumber,
  isIdentificationNumberValid,
  setIsIdentificationNumberValid,
  setIdentificationNumber,
  step,
  setStep,
  cookieToken
}) {
  const navigate = useNavigate();
  const [newEmail, setNewEmail] = useState(client_email);
  const [offerData, setOfferData] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [mapsKey, setMapsKey] = useState(false);
  let [modalVisible, setModalVisible] = useState(false);
  const [open, setOpen] = React.useState(modalVisible);
  let [option, setOption] = useState({});
  const [errorMessageLg, setErroMessageLg] = useState("");


  /** MESSAGE STATUS */
  const [status, setStatusError] = useState(null);
  const [messageError, setMessageError] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);

  /** COOKIES */
  const [cookies, setCookie, removeCookie] = useCookies([
    "login-kiwox-cookies",
  ]);

  // Funcion para recuperar los grupos de planes
  const fetchGroupData = async () => {
    const token = cookies["login-kiwox-cookies-token"];
    const url = "/planGroup/getPlanGroups";

    const response = await axiosPost(url, token);
    if (response.status.code === 200) {
      setGroupData(response.data);
      localStorage.setItem("userToken", JSON.stringify(response.token));
      removeCookie("login-kiwox-cookies-token");
      setCookie("login-kiwox-cookies-token", JSON.stringify(response.token), {
        path: "/",
      });
    } else if (response.status.code === 401) {
      console.debug("Token not valid!");
      navigate("/logout");
    } else {
      localStorage.setItem("userToken", JSON.stringify(response.token));
      removeCookie("login-kiwox-cookies-token");
      setCookie("login-kiwox-cookies-token", JSON.stringify(response.token), {
        path: "/",
      });
    }
  };

  const handleEmailValidation = (e) => {
    const emailRegex = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const newEmail = e.target.value;
    // Perform your validation here
    setNewEmail(newEmail);

    const matches = newEmail.match(emailRegex);
    setIsEmailValid(!!matches);
  };

  const handleIdValidation = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove all non-digit characters
    const limitedValue = value.slice(0, 9); // Limit the value to 9 digits
    setIdentificationNumber(limitedValue);

    const idRegex = /^\d{9}$/;
    const matches = limitedValue.match(idRegex);
    setIsIdentificationNumberValid(!!matches);
  };

  const handleClick = (event) => {
    event.preventDefault(); // Prevents the default action of the link
    // Add your custom logic here
    setModalVisible(true);
  };


  // Crear ticket
  const saveChangesDB = async (data) => {
    const token = cookieToken;
    const url = "/insurance/verify/info";
    try {
      const response = await axiosPost(url, token, data); // Using axiosPost as before

      if (response.status.code === 200) {
        return response.data;
      } else {
        // Handle other status codes here if needed
        console.debugor("Unhandled response status code:", response.status);
        return null;
      }
    } catch (error) {
      // Centralized error handling for network errors
      setMessageError(
        "Ha ocurrido un error, por favor vuelva a intentar."
      );
      setStatusError(error.response ? error.response.status : 500);
      setShowErrorModal(true);
      return null;
    }
  };

  async function handleButton(event) {
    try {
      if (isEmailValid === true && isIdentificationNumberValid === true) {
        const ticketData = await saveChangesDB({ token: cookieToken, email: newEmail, serie_ci: identificationNumber });
        if (ticketData) {
          setErroMessageLg("");
          setClientEmail(newEmail);
          setIdentificationNumber(identificationNumber);
          setStep(step + 1);

        }
      } else {
        if (newEmail === null || newEmail === "") {
          setIsEmailValid(false);
          setErroMessageLg("Debe ingresar un correo electrónico");
        }
        if (identificationNumber === "") {

          setIsIdentificationNumberValid(false);
          setErroMessageLg("Debe ingresar el número de serie de su carnet");
        }
        if ((identificationNumber != "" && !isIdentificationNumberValid)) {
          setErroMessageLg("El número de serie de su carnet debe contener 9 dígitos ");
        }
        if ((newEmail != "" && !isEmailValid)) {
          setErroMessageLg("Ingrese un correo electrónico valido");
        }
        if (identificationNumber === "" && newEmail === "") {
          setErroMessageLg("Debe ingresar ambos campos");
        }

      }
    } catch (error) {
      console.debug(error);
    }
  };

  function maskIMEI(imei) {
    return "*********" + imei.slice(9);
  }

  return (
    <div className="col-12">
      <div className="col-12 d-flex justify-content-center">
        <div className="col-lg-8 col-md-9 col-sm-10 col-11 row justify-content-center">
          {/*--------------- START DATOS PERSONALES ---------------*/}
          <div className="d-flex justify-content-center mt-4 mb-2">
            <h3 className="fw-light blue-text">Mis datos personales</h3>
          </div>
          <div className="d-flex justify-content-center">
            <Typography
              variant="h6"
              className="mt-1 mb-1 col-lg-6 col-md-8 col-sm-10 col-12"
              id="modal-modal-title"
            >
              Nombre: {client_name} <br />
              Rut: {FormatearRut(rut)} <br />
              Email: {client_email}
            </Typography>
          </div>
          {/*--------------- END DATOS PERSONALES ---------------*/}
          {/*--------------- START EQUIPO ---------------*/}
          <div className="d-flex justify-content-center mt-4 mb-2">
            <h3 className="fw-light blue-text">Mi Equipo Protegido</h3>
          </div>
          <div className="d-flex justify-content-center row">
            <Typography
              variant="h6"
              class="col-lg-4 col-md-5 col-sm-6 col-12 ps-0"
              id="modal-modal-title"
            >
              {brand} {model} <br />
              IMEI {maskIMEI(imei)} <br />
            </Typography>
            <Typography
              variant="h6"
              class="text-end col-lg-2 col-md-3 col-sm-4 col-12 blue-text ps-0"
              id="modal-modal-title"
            >
              <strong>${value.toLocaleString("es-CL")}</strong>/mes <br />
              en tu boleta <br />
            </Typography>
          </div>
          {/*--------------- END EQUIPO ---------------*/}
          {/*--------------- START IDENTIDAD ---------------*/}
          <div className="d-flex justify-content-center mt-4 mb-2">
            <h3 className="fw-light blue-text">Verificación de identidad</h3>
          </div>
          <div className="col-12 d-flex justify-content-center">
            <div className="col-lg-6 col-md-8 col-sm-10 col-12 row justify-content-center">
              <div className="col-12">
                <label className="col-12 p-0">
                  Número de serie carnet de identidad <br />{" "}
                  <div className="row col-12 m-0 p-0">
                    <TextField
                      className="textFieldBox col-12"
                      required
                      type="text"
                      name="identificationNumber"
                      style={{ boxSizing: "border-box !important" }}
                      value={identificationNumber}
                      onChange={handleIdValidation}
                      inputProps={{ maxLength: 9, pattern: "[0-9]*" }}
                    />
                    { /*   {identificationNumber !== null && (<>
                  {isIdentificationNumberValid ? (
                    <span className="col-1 m-auto p-auto"><CheckIcon style={{ color: "#5CD100" }} /></span>
                  ) : (
                    <span className="col-1 m-auto p-auto"><CloseIcon style={{ color: "#FF2929" }} /></span>
                  )}
                </>
                )}*/}
                  </div>
                </label>
              </div>
            </div>
          </div>
          {/*--------------- END IDENTIDAD ---------------*/}
          <div className="d-flex justify-content-center mt-4 mb-2">
            <h3 className="fw-light blue-text">Actualizar información</h3>
          </div>
          <div className="col-12 d-flex justify-content-center">
            <div className="col-lg-6 col-md-8 col-sm-10 col-12 row justify-content-center">
              <div className="col-12 my-2">
                <label className="col-12 p-0">
                  Correo electrónico <br />{" "}
                  <div className="row col-12 m-0 p-0">
                    <TextField
                      className="textFieldBox col-12"
                      required
                      type="text"
                      name="emailInput"
                      style={{ boxSizing: "border-box !important" }}
                      value={newEmail}
                      onChange={handleEmailValidation}
                    />
                    { /* 
                 {client_email !== null && (<>
                  {isEmailValid ? (
                    <span className="col-1 m-auto p-auto"><CheckIcon style={{ color: "#5CD100" }} /></span>
                  ) : (
                    <span className="col-1 m-auto p-auto"><CloseIcon style={{ color: "#FF2929" }} /></span>
                  )}
                </>
                  )} */}
                  </div>
                </label>
              </div>
            </div>
          </div>
          {/*--------------- START BTN ---------------*/}
          <label className="col-12 mt-5 mb-2 justify-content-center m-auto p-auto">
            <Typography variant="body2" className="col-12 text-center">
              Guardamos tus datos de forma segura
            </Typography>
          </label>
          <div className=" d-flex justify-content-center">
            <Button
              className="btn-principal mt-1 col-md-5 col-sm-7 col-9"
              variant="outlined"
              onClick={handleButton}
            >
              Guardar
            </Button>
          </div>
          <label className="col-12  my-2 justify-content-center m-auto p-auto">
            {errorMessageLg && (
              <Typography
                variant="body2"
                className="red-cross col-12 text-center"
              >
                {errorMessageLg}
              </Typography>
            )}
          </label>
          {modalVisible && (
            <ImeiModal
              modalVisible={modalVisible}
              option={option}
              setModalVisible={setModalVisible}
            />
          )}
          {/*--------------- END BTN ---------------*/}
        </div>
      </div>
    </div>
  );
}
export default InsuranceForm;
