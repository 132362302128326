import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from "./routes/routes";
import 'popper.js';
import 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import './common/styles/css/index.css';
import './common/styles/css/custom-kiwox.css';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router />
    </ThemeProvider>
  </React.StrictMode>,
);