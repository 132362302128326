import React, { useState, useEffect } from "react";
import { useCookies } from 'react-cookie';
import { Box, Button } from "@mui/material";
import MenuBar from "../../common/components/menuBar/menuBar";
import Footer from "../../common/components/footer/footer";
import InsuranceEntry from "../../components/forms/insuranceEntry";
import InsuranceForm from "../../components/forms/insuranceForm";
import InsuranceTyc from "../../components/forms/insuranceTyc";
import MultiStep from "../../components/multistep/multiStepUi";
import { ValidarRut } from "../../common/styles/js/common/custom-kiwox.js";
import { axiosPost } from "../../common/services/axios/apiCalls.js";
import { useNavigate } from "react-router-dom";

function InsuranceContract() {

  const fetchParam = async () => {
    const token = cookies["login-kiwox-cookies-token"];
    const url = "/insurance/get/token";

    const response = await axiosPost(url,token, { token });
    if (response.status.code === 200) {
      const data = response.data ? response.data : [];
      setBrand(response.data.brand);
      setClientName(response.data.client_name)
      setChannel(response.data.channel)
      setClientContact(response.data.client_contact)
      setComercialGamma(response.data.comercial_gamma)
      setCreatedOn(response.data.created_on)
      setHiredDate(response.data.hired_date)
      setID(response.data.id)
      setImei(response.data.imei)
      setLastVisit(response.data.last_visit_date)
      setModel(response.data.model)
      setOS(response.data.operative_system)
      setOrderNumber(response.data.order_number)
      setState(response.data.state)
      setToken(response.data.token)
      setValue(response.data.value)
      setVisits(response.data.visits)
      setClientEmail(response.data.client_email)
      setRut(response.data.rut)

      setMapsKey(data.value ? data.value : "");

    } else if (response.status.code === 401 || response.status.code === 400) {
      navigate("/");
    }
  };


   //InsuranceEntry Data
   const [step, setStep] = useState(0); // State to manage which step to show
   const [result, setResult] = useState("");
   const [buttonContinue, setButtonContinue] = useState(false);
   const [buttonBack, setButtonBack] = useState(false);


   //InsuranceEntry Personal Data

   const [isIdentificationNumberValid, setIsIdentificationNumberValid] = useState(false);
   const [isImeiValid, setIsImeiValid] = useState(false);
   const [isRutValid, setIsRutValid] = useState(false);
   const [isEmailValid, setIsEmailValid] = useState(true);
   const [identificationNumber, setIdentificationNumber] = useState("");
   const [brand, setBrand] = useState("");
   const [channel, setChannel] = useState("");
   const [client_contact, setClientContact] = useState("");
   const [client_email, setClientEmail] = useState("");
   const [client_name, setClientName] = useState("");
   const [comercial_gamma, setComercialGamma] = useState("");
   const [created_on, setCreatedOn] = useState("");
   const [hired_date, setHiredDate] = useState("");
   const [id, setID] = useState("");
   const [imei, setImei] = useState("");
   const [last_visit_date, setLastVisit] = useState("");
   const [model, setModel] = useState("");
   const [operative_system, setOS] = useState("");
   const [order_number, setOrderNumber] = useState("");
   const [rut, setRut] = useState("");
   const [state, setState] = useState("");
   const [token, setToken] = useState("");
   const [value, setValue] = useState("");
   const [visits, setVisits] = useState("");
   const formDataClient = {
    brand: brand,
    channel: channel,
    client_contact: client_contact,
    client_email: client_email,
    client_name: client_name,
    comercial_gamma: comercial_gamma,
    created_on: created_on,
    hired_date: hired_date,
    id: id,
    imei: imei,
    last_visit_date: last_visit_date,
    model: model,
    operative_system: operative_system,
    order_number: order_number,
    rut: rut,
    state: state,
    token: token,
    value: value,
    visits: visits
  };
  
  useEffect(() => {
    fetchParam();

  }, []);
  
  /** CONST */
  const [useNavbar, setUseNavbar] = useState(false)
  const [useClient, setUseClient] = useState(false)
  const navigate = useNavigate();
  const [mapsKey, setMapsKey] = useState(false);

  /** COOKIES */
  const [cookies, setCookie, removeCookie] = useCookies(['login-kiwox-cookies']);
  const [cookieToken,setCookieToken] = useState(cookies["login-kiwox-cookies-token"])
  const [cookieSession,setCookieSession] = useState(cookies["login-kiwox-cookies-session"])

  const nextStep = () => {
    try {
      if (
        isRutValid === true &&
        isEmailValid === true &&
        ValidarRut(rut) === true
      ) {
        setStep(step + 1);
      } else {
        if (rut === null) {
          setRut("");
          setIsRutValid(false);
        }
        if (client_email === null) {
          setClientEmail("");
          setIsEmailValid(false);
        }
        return;
      }
    } catch (error) { console.debug(error); }

  };

  const backStep = () => {
    setStep(step - 1);
    setButtonContinue(false);
  };

  const handleResult = (btnValue) => {
    setResult(btnValue);
    setStep(step + 1);
  };

  //useEffect(() => {
    //if (cookies['login-kiwox-cookies-login'].channel == 'WEB')
    //  setUseNavbar(true);
    //if (cookies['login-kiwox-cookies-login'].channel != 'QR')
    //  setUseClient(true);
  //}, [])

  return (
    <div>
      <MenuBar useNavbar={useNavbar} useClient={useClient} />
      <div className="container-xxl">
        <Box className="mt-5">
          <MultiStep step={step} />
          <div className="d-flex justify-content-center">
            {step === 0 && (
              <InsuranceEntry
                dataClient={formDataClient}
                client_name={client_name}
                brand = {brand}
                model = {model}
                imei = {imei}
                value = {value}
                isImeiValid = {isImeiValid}
                setIsImeiValid = {setIsImeiValid}
                rut= {rut}
                setIsRutValid= {setIsRutValid}
                isRutValid= {isRutValid}
                setStep = {setStep}
                step = {step}
              />
            )}
            {step === 1 && (
              <InsuranceForm onResult={handleResult}
              dataClient={formDataClient} 
              brand = {brand}
              model = {model}
              imei = {imei}
              value = {value}
              client_name = {client_name}
              rut = {rut}
              client_email = {client_email}
              setRut = {setRut}
              setClientEmail = {setClientEmail}
              setIsRutValid = {setIsRutValid}
              setIsEmailValid = {setIsEmailValid}
              isEmailValid = {isEmailValid}
              identificationNumber= {identificationNumber}
              isIdentificationNumberValid = {isIdentificationNumberValid}
              setIsIdentificationNumberValid ={setIsIdentificationNumberValid}
              setIdentificationNumber = {setIdentificationNumber}
              setStep = {setStep}
              step = {step}
              cookieToken = {cookieToken}
              />
            )}
            {step === 2 && (
              <InsuranceTyc
                brand = {brand}
                model = {model}
                imei = {imei}
                value = {value}
                client_name = {client_name}
                rut = {rut}
                client_email = {client_email}
                nextStep = {nextStep}
                dataClient = {formDataClient}
                cookieToken = {cookieToken}
                cookieSession = {cookieSession}
              />
            )}
          </div>
          {/* Next button to proceed to the next step 
          <div className="d-flex justify-content-center">
            {step === 1 && (
              <div className="col-lg-6 col-md-9 col-sm-10 col-11 d-flex justify-content-center">
                {!buttonContinue && (
                  <Button
                    className="btn-principal mt-5 col-md-5 col-sm-7 col-9"
                    variant="outlined"
                    onClick={nextStep}
                  >
                  {step === 0 && "Quiero proteger mi equipo"}
                  {step === 1 && "Guardar"}
                  {step === 2 && "Continuar"} 
                  </Button>
                )}
              </div>
            )}
          </div>*/}
        </Box>
      </div>
      <Footer />
    </div>
  );
}

export default InsuranceContract;
