import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { axiosPost } from "../../common/services/axios/apiCalls.js";
import { useAuth } from "../../context/authContext.js";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ImeiModal from "./imeiModal.js";
import {
  ValidarRut,
  FormatearRut,
  ValidarFormatoRut,
} from "../../common/styles/js/common/custom-kiwox.js";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function InsuranceEntry({
  client_name,
  imei,
  isImeiValid,
  setIsImeiValid,
  model,
  brand,
  setStep,
  step,
  rut,
  setIsRutValid,
  isRutValid,
  value
}) {
  /** COOKIE */
  // const { cookies, setCookie, removeCookie } = useAuth();

  /** CONST */
  let [modalVisible, setModalVisible] = useState(false);
  const [open, setOpen] = React.useState(modalVisible);
  let [option, setOption] = useState({});
  let [newRut, setNewRut] = useState("");
  let [newImei, setNewImei] = useState("");
  const [errorMessageLg, setErroMessageLg] = useState("");

  const handleClick = (event) => {
    event.preventDefault(); // Prevents the default action of the link
    // Add your custom logic here
    setModalVisible(true);
  };

  const handleRutValidation = (e) => {
    newRut = e.target.value.slice(0, 12);
    setNewRut(FormatearRut(newRut));
    ValidarFormatoRut(newRut);
    const matches = FormatearRut(newRut) === FormatearRut(rut);
    setIsRutValid(!!matches);
  };

  const handleImeiValidation = (e) => {
    newImei = e.target.value.slice(0, 15);
    setNewImei(newImei);
    const matches = newImei === imei;
    setIsImeiValid(!!matches);
  };

  function maskIMEI(imei) {
    return "*********" + imei.slice(9);
  }

  const handleButton = (event) => {
    try {
      //&& isImeiValid === true
      if (isRutValid === true) {
        setStep(step + 1);
        setErroMessageLg("");
      } else {
        //|| isImeiValid === false
        if (newRut === "") {
          setErroMessageLg("Llenar los campos para continuar");
        } else {
          setErroMessageLg("Rut no pertenece al beneficiario de la promoción");
        }
        return;
      }
    } catch (error) {
      console.debug(error);
    }
  };

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&::before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "light"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));

  //Tabla deducible
  function createData(name, amount) {
    return { name, amount };
  }

  const rows = [
    createData("Pérdida Total en caso de Daños Accidentales", " $ 99.990"),
    createData("Pérdida Parcial en caso de Daños Accidentales", "$ 29.990"),
    createData("GARANTÍA EXTENDIDA", "$ 0"),
  ];

  return (
    <div className="col-12">
      <div className="d-flex justify-content-center my-3 py-2">
        <h3 className="fw-light fw-bold">¡Hola {client_name}!</h3>
      </div>
      <div className="col-12 d-flex justify-content-center">
        <div className="col-lg-8 col-md-9 col-sm-10 col-11 row justify-content-center">
        <div className="d-flex justify-content-center mt-4 mb-2">
          <h3 className="fw-light blue-text">Mi Equipo Protegido</h3>
          </div>
          <div className="d-flex justify-content-center ">
            <Typography
              variant="h6"
              className="mt-1 mb-1 col-lg-6 col-md-8 col-sm-10 col-12"
              id="modal-modal-title"
            >
              {brand} {model} <br />
              IMEI {maskIMEI(imei)} <br />
              ${value.toLocaleString("es-CL")}/mes en tu boleta
            </Typography>
          </div>
          <div className="d-flex justify-content-center mt-4 mb-2">
          <h3 className="fw-light blue-text">Ingresa aquí</h3>
          </div>
          <div className="d-flex justify-content-center">
            <label className="col-lg-6 col-md-8 col-sm-10 col-12">
              Rut <span className="text-secondary"></span>
              <br />{" "}
              <div className="row col-12 m-0 p-0">
                <TextField
                  className="textFieldBox col-12"
                  required
                  type="text"
                  name="rutInput"
                  style={{ boxSizing: "border-box !important" }}
                  onChange={handleRutValidation}
                  value={FormatearRut(newRut)}
                />
              </div>
            </label>
          </div>

          <div className="d-flex justify-content-center">
            <Button
              className="btn-principal mt-5 col-lg-4 col-md-5 col-sm-7 col-11"
              variant="outlined"
              onClick={handleButton}
            >
              Quiero proteger mi equipo
            </Button>
          </div>
          <label className="col-12  my-2 justify-content-center m-auto p-auto">
            {errorMessageLg && (
              <Typography
                variant="body2"
                className="red-cross col-12 text-center"
              >
                {errorMessageLg}
              </Typography>
            )}
          </label>
          <div className="d-flex justify-content-center mt-5 mb-2">
            <h3 className="fw-light blue-text">
              ¿Qué cubre Mi Equipo Protegido de Chubb?
            </h3>
          </div>
          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Daños accidentales
              </AccordionSummary>
              <AccordionDetails>
                Aquellos daños causados por un accidente , tales como: <br></br>
                &nbsp; • Caídas accidentales <br></br>
                &nbsp; • Roturas de pantalla <br></br>
                &nbsp; • Daño por derrame de líquidos
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Garantia extendida
              </AccordionSummary>
              <AccordionDetails>
                Beneficio adicional al contratar el seguro colectivo de CHUBB,
                el cual busca prolongar el tiempo de cobertura del equipo móvil
                una vez terminada la garantía del fabricante (fallas producidas
                por defectos de fábrica del equipo). Tiene cobertura desde el
                mes 12 de la contratación del seguro, no se paga deducible y no
                tiene límite de eventos durante el periodo el mes 12 al 18.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                Vigencia
              </AccordionSummary>
              <AccordionDetails>
                La vigencia del seguro es por 18 meses con renovación automática
                por 18 meses más.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Deducible
              </AccordionSummary>
              <AccordionDetails>
                <div className="col-12 tabla-interna">
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ width: "20vw" }}>
                            COBERTURA
                          </TableCell>
                          <TableCell align="left" sx={{ width: "20vw" }}>
                            DEDUCIBLE
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ width: "20vw" }}
                            >
                              {row.name}
                            </TableCell>
                            <TableCell align="left" sx={{ width: "20vw" }}>
                              {row.amount}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="d-flex justify-content-center mt-4 mb-2">
            <h3 className="fw-light blue-text">Preguntas frecuentes</h3>
          </div>
          <div>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                ¿Cuándo comienza a cubrir el seguro?
              </AccordionSummary>
              <AccordionDetails>
                La cobertura comienza a regir de forma inmediata una vez
                finalizada y aceptada la contratación a través de la página web.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                ¿Cuándo debo hacer la declaración del siniestro
              </AccordionSummary>
              <AccordionDetails>
                Lo más pronto posible desde que ocurrieron los hechos.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                ¿Quién puede acceder al seguro?
              </AccordionSummary>
              <AccordionDetails>
                Todos los clientes con al menos una línea Post pago Entel que
                hayan adquirido equipo nuevo en venta o arriendo.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                ¿El seguro cubre daños cosmeticos?
              </AccordionSummary>
              <AccordionDetails>
                No. El seguro no cubre daños tales como; rayaduras, surcos y
                cualquier otro tipo de daño superficial.
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                ¿Qué pasa si quiero terminar mi seguro?
              </AccordionSummary>
              <AccordionDetails>
                Puedes terminar el contrato de seguro en cualquier momento. El
                cobro proporcional se verá reflejado en su cuenta del mes
                siguiente.
              </AccordionDetails>
            </Accordion>
            {modalVisible && (
              <ImeiModal
                modalVisible={modalVisible}
                option={option}
                setModalVisible={setModalVisible}
              />
            )}
          </div>

          {/* -------------------- END FORMULARIO -------------------- */}
        </div>
      </div>
    </div>
  );
}
export default InsuranceEntry;
