import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/authContext";
import {
  Modal,
  Box,
  Typography,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { common } from "@mui/material/colors";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { FormatearRut } from "../../common/styles/js/common/custom-kiwox.js";

//const ImagenImei = "http://localhost/static/smartcampaign/numero-imei-importante-06.jpg";
const ImagenImei = "/static/smartcampaign/numero-imei-importante-06.jpg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: "320px",
};

function InsuranceModal({ modalVisible, option, setModalVisible }) {
  const { cookies, setCookie, removeCookie } = useAuth();
  const [selectedValue, setSelectedValue] = useState(""); // State to track selected value
  const [open, setOpen] = useState(modalVisible);
  const [showDetails, setShowDetails] = useState(false);
  const number = option.form_request_number;
  const nombre = option.form_request_number;
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const navigate = useNavigate();

  function handleClick() {
    //if (cookies["login-kiwox-cookies-login"].channel == "TOKEN")
    setModalVisible(false);
    // else window.location.reload();
  }

  useEffect(() => {
    //   if (cookies["login-kiwox-cookies-login"].channel != "QR")
    setShowDetails(true);
  }, []);

  return (
    <div>
      {/* -------------------- START MODAL -------------------- */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{ onClick: null }} // Disable backdrop click
      >
        {/* -------------------- START BOX -------------------- */}

        <Box className="custom-modal box-modal box-modal-lg text-center ">
          <div className="d-flex justify-content-end">
            <IconButton
              aria-label="delete"
              size="large"
              onClick={() => handleClick()}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div class="p-4">
          <Typography
            variant="h4"
            className="mt-3 mb-5 sm blue-text"
            id="modal-modal-title"
          >
            Cómo saber mi IMEI
          </Typography>
          <>
            <Typography
              variant="h6"
              align="left"
              className="text-black-100 "
              id="modal-modal-description"
            >
              Desde el teléfono debes marcar <strong>*#06#</strong> y automáticamente aparecerá
              el IMEI en pantalla.
            </Typography>

            <Typography
              variant="body2"
              align="left"
              className="text-black-50 "
              id="modal-modal-description"
            >
              Tú equipo puede tener uno o dos IMEI, en esta ocasión, sólo
              necesitarás el <strong>número del IMEI1</strong>
            </Typography>
            <div class="mt-3 mb-5 py-1">
              <div>
              <img src={ImagenImei} class= "img-fluid imei-smart" alt="imei-smart" />
              </div>
            </div>
          </>
          </div>
        </Box>
        {/* -------------------- END BOX -------------------- */}
      </Modal>
      {/* -------------------- END MODAL -------------------- */}
    </div>
  );
}

export default InsuranceModal;
