/**
 * Esta función evita números negativos, devolviendo 0 si el número es negativo.
 * @param {number} number - El número a ser evaluado.
 * @returns {number} - 0 si el número es negativo, de lo contrario, devuelve el mismo número.
 */
export function PreventNegative(number) {
  return number < 0 ? 0 : number;
}
/**
 * Esta función valida un RUT (Rol Único Tributario) en Chile.
 * @param {string} rut - El RUT a ser validado.
 * @returns {boolean} - true si el RUT es válido, false de lo contrario.
 */
export function ValidarRut(rut) {
  rut = rut.replace(/[^\dKk]/g, "");
  if (rut.length < 8) {
    return false;
  }
  const num = rut.slice(0, -1);
  const dv = rut.slice(-1).toUpperCase();
  let suma = 0;
  let multiplo = 2;
  for (let i = num.length - 1; i >= 0; i--) {
    suma += parseInt(num.charAt(i)) * multiplo;
    if (multiplo < 7) {
      multiplo += 1;
    } else {
      multiplo = 2;
    }
  }
  const dvEsperado = 11 - (suma % 11);
  if (dvEsperado === 10) {
    return dv === "K";
  } else if (dvEsperado === 11) {
    return dv === "0";
  } else {
    return parseInt(dv) === dvEsperado;
  }
}
/**
 * Esta función valida el formato de un RUT en Chile.
 * @param {string} rut - El RUT a ser validado en formato xx.xxx.xxx-d.
 * @returns {boolean} - true si el formato del RUT es válido, false de lo contrario.
 */
export function ValidarFormatoRut(rut) {
  const formatoRut = /^\d{1,8}-[0-9kK]$/;
  return formatoRut.test(rut);
}
/**
 * Esta función formatea un porcentaje agregando separadores de miles y dos decimales.
 * @param {number} percentage - El porcentaje a ser formateado.
 * @returns {string} - El porcentaje formateado.
 */
export function FormatPercentage(percentage) {
  const formattedPercentage = Number(percentage).toFixed(2);
  const parts = formattedPercentage.split(".");
  const integerPart = Number(parts[0]).toLocaleString("es-CL");
  const decimalPart = parts[1];
  return `${integerPart},${decimalPart}`;
}
/**
 * Esta función formatea un número de teléfono en el formato (x) xxxx xxxx.
 * @param {string} phoneNumber - El número de teléfono a ser formateado.
 * @returns {string} - El número de teléfono formateado.
 */
export function FormatPhoneNumber(phoneNumber) {
  const cleanedNumber = phoneNumber.replace(/\D/g, "");
  const formattedNumber = cleanedNumber.replace(
    /(\d{1})(\d{4})(\d{4})/,
    `($1) $2 $3`
  );
  return formattedNumber;
}
/**
 * Esta función formatea un RUT en el formato tu válido.
 * @param {string} rut - El RUT a ser formateado.
 * @returns {string} - El RUT formateado.
 */
 export const FormatearRut = (rut) => {
  const rutNumerico = rut.replace(/[^\dkK\d]/g, '');
  if (rutNumerico === "") {
    return "";
  }
  const rutParteNumerica = rutNumerico.slice(0, -1);
  const rutDigitoVerificador = rutNumerico.slice(-1);

  return `${rutParteNumerica.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}-${rutDigitoVerificador.toUpperCase()}`;
};
export const hasPermissonAction = (obj, key) => {
  // Verifica si el objeto tiene la clave
  if (obj.hasOwnProperty(key)) {
    // Verifica si el valor de la clave es igual a 1
    if (obj[key] === 1) {
      return true; // La clave existe y el valor es 1
    } else {
      return false; // La clave existe pero el valor no es 1
    }
  } else {
    return false; // La clave no existe en el objeto
  }
}