import axios from "axios";

/**
 * 
 * @param {String} endpoint 
 * @param {String} token 
 * @param {{}} data 
 * @returns 
 */
export async function axiosPost(endpoint, token, data = {}) {
  const apiConfig = {
    method: "POST",
    baseURL: process.env.REACT_APP_SCAM_API_URL,
    mode: "cors",
    url: endpoint,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    },
    data
  };

  try {
    const response = await axios(apiConfig);
    return response.data;
  } catch (error) {
    const errorResponse = error.response
    if (errorResponse.status && errorResponse.status === 401) {
      console.debug('Token not valid!')
    }
    return errorResponse.data
  }
};

export async function axiosGet(endpoint, token, params = {}) {
  const apiConfig = {
    method: "GET",
    baseURL: process.env.REACT_APP_SCAM_API_URL,
    mode: "cors",
    url: endpoint,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    },
    params
  };

  try {
    const response = await axios(apiConfig);
    return response.data;
  } catch (error) {
    const errorResponse = error.response
    if (errorResponse.status && errorResponse.status === 401) {
      console.debug('Token not valid!')
    }
    return errorResponse.data
  }
};