import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCookies } from 'react-cookie';
import axios from "axios";
import SetLoading from "../modals/setLoadingModal";
//import { useAuth } from "../../../context/authContext";
import { axiosPost } from '../../services/axios/apiCalls'

function Login({ mode }) {
  /** CONST */
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const { token } = useParams();

  /** COOKIES */
  const [cookies, setCookie, removeCookie] = useCookies(['login-kiwox-cookies']);

  /** Custom Functions */
  async function unsubscribe(token) {
    console.debug('unsubscribing ' + token)
    removeCookie('login-kiwox-cookies-session', { path: '/' })
    removeCookie('login-kiwox-cookies-token', { path: '/' });
    removeCookie('login-kiwox-cookies-redirect', { path: '/' });

    const url = "/insurance/unsubscribe/token";

    try {
      setLoading(true)
      const response = await axiosPost(url, token)
      if (response.status.code === 200) {
        setLoading(false)
        navigate('/ex');
      } else {
        console.debug(response.data)
        setLoading(false)
        navigate('/');
      }
    } catch (error) {
      console.debug(error)
      setLoading(false)
      navigate('/');
    }
  }

  /** Login Functions */
  async function checkUser(token) {
    console.debug('DB')
    const url = "/insurance/login/token";

    try {

      const response = await axiosPost(url, token, { token });
      if (response.status.code === 200) {
        setCookie('login-kiwox-cookies-token', JSON.stringify(token), { path: '/' })
        setCookie('login-kiwox-cookies-session', JSON.stringify(response.data.sessionId), { path: '/' })
        setLoading(false)
        console.info('Navigating ofertaseguro...')
        navigate('/ofertaseguro');
      } else {
        console.debug(response)
        setLoading(false)
        navigate('/');
      }
    } catch (error) {
      removeCookie('login-kiwox-cookies-session', { path: '/' })
      removeCookie('login-kiwox-cookies-token', { path: '/' })
      removeCookie('login-kiwox-cookies-redirect', { path: '/' })
      setLoading(false)
    }
  }

  function authenticate(channel, token, qr) {
    console.debug('authenticating with ' + channel);
    removeCookie('login-kiwox-cookies-session', { path: '/' })
    removeCookie('login-kiwox-cookies-token', { path: '/' });
    removeCookie('login-kiwox-cookies-redirect', { path: '/' });

    setLoginCookie(channel, token, qr);
    checkUser(token)
  }

  function setLoginCookie(channel, token, qr) {
    console.debug('Setting cookie');
    removeCookie('login-kiwox-cookies-login', { path: '/' });
    const plattform = process.env.NODE_ENV === 'development' ? window.location.href : window.location.origin;

    const loginKiwoxCookie = {
      channel,
      plattform,
      token,
    };
    setCookie('login-kiwox-cookies-login', JSON.stringify(loginKiwoxCookie), { path: '/' });
  }

  useEffect(() => {
    setLoading(true)
    if (token) {
      if (mode == 'UNSUBSCRIBE') {
        unsubscribe(token)
      } else {
        authenticate('WEB', token)
      }
    } else {
      setLoading(false)
      navigate('/');
    }
  }, []);

  return (
    <div className="bg-login">
      {loading &&
        <SetLoading />
      }
    </div>
  );
}

export default Login;
