import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../common/components/login/login";
import Logout from "../common/components/login/logout";
import NotFound from "../common/components/notFound/notFound";
import { AuthProvider } from "../context/authContext";
import InsuranceContract from "../pages/forms/insuranceContract";
import NotOfferFound from "../components/notFound/noOfferFound"
import ExcludeOffer from "../components/notFound/excludeOffer";

function Router() {
  const notFoundMessage = "La página que estás buscando no ha sido encontrada."
  const notFoundOfferMessage = "En este momento no cuenta con una oferta disponible."
  const unsuscribedMessage = "Su solicitud de excluir ha sido registrada exitosamente."

  const unsubscribeMode = 'UNSUBSCRIBE'

  return (
    <BrowserRouter basename={`${process.env.REACT_APP_SCAM_BASENAME}`}>
      <AuthProvider>
        <Routes>
          <Route path="" element={<NotOfferFound message={notFoundOfferMessage} />} />
          <Route path="/ex" element={<ExcludeOffer message={unsuscribedMessage} />} />
          <Route path="/:token" element={<Login />} />
          <Route path="/ex/:token" element={<Login mode={unsubscribeMode} />} />
          <Route path="/ofertaseguro" element={<InsuranceContract />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/notFound" element={<NotFound message={notFoundMessage} />} />
          <Route path="*" element={<NotFound message={notFoundMessage} />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default Router;
