import { useCookies } from 'react-cookie';
import { useAuth } from "../../../context/authContext";

function Logout() {
  /** COOKIES */
  const [cookies, setCookie, removeCookie] = useCookies(['login-kiwox-cookies']);
  //const { setUser } = useAuth();

  //setUser(null);
  localStorage.clear("userName");
  localStorage.clear("userToken");
  localStorage.clear("userPrivileges");
  localStorage.clear("reload");
  removeCookie('login-kiwox-cookies-token', { path: '/' })
  removeCookie('login-kiwox-cookies-redirect', { path: '/' })
  console.debug('LOGOUT')
  window.location.href = `${process.env.REACT_APP_LOGIN_REACT_URL}`
}

export default Logout;
