import React from "react";
import { Box, Container, Typography } from "@mui/material";

function ExcludeOffer({ message }) {
  return (
    <div className="bg-login">
      <Container
        className="d-flex justify-content-center pt-5"
        component="main"
      >
        <Box className="kiwox-panel shadow d-flex flex-column align-items-center p-4">
          <div className="pb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              fill="currentColor"
              class="bi bi-exclamation-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
              <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
            </svg>
          </div>
          <div>
            <Typography className="center-elements" variant="body1">
              {message}
            </Typography>
          </div>
        </Box>
      </Container>
    </div>
  );
}

export default ExcludeOffer;
