import React from "react";
import { Modal, Box, Typography } from "@mui/material";

function SetLoading({ imageSource }) {
    const image = imageSource ? imageSource: 'navbar-logo.png';
    return (
        <Modal className="loading-modal-container" open={true}>
            <Box className="loading-container">
                <img
                    className="loading-image-static"
                    src={image}
                    alt="Loading animation"
                />
            </Box>
        </Modal>
    );
}

export default SetLoading;