import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/authContext";
import { Modal, Box, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { FormatearRut } from "../../common/styles/js/common/custom-kiwox.js";
import { useNavigate } from "react-router-dom";

const LogoFibra = "/static/app/icon-fibra.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  height: "320px",
};

function InsuranceModal({
  modalVisible,
  option,
  imei,
  brand,
  client_name,
  model,
  rut,
  client_email,
  value
}) {
  const { cookies, setCookie, removeCookie } = useAuth();
  const [selectedValue, setSelectedValue] = useState(""); // State to track selected value
  const [open, setOpen] = useState(modalVisible);
  const [showDetails, setShowDetails] = useState(false);
  const number = option.form_request_number;
  const nombre = option.form_request_number;
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const navigate = useNavigate();

  function handleClick() {
    //if (cookies["login-kiwox-cookies-login"].channel == "TOKEN")
    window.close();
    // else window.location.reload();
  }

  useEffect(() => {
    //   if (cookies["login-kiwox-cookies-login"].channel != "QR")
    setShowDetails(true);
  }, []);

  function maskIMEI(imei) {
    return "*********" + imei.slice(9);
  }

  return (
    <div>
      {/* -------------------- START MODAL -------------------- */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{ onClick: null }} // Disable backdrop click
      >
        {/* -------------------- START BOX -------------------- */}

        <Box className="custom-modal box-modal box-modal-smartCampaign">
          {/*}<div className="d-flex justify-content-end">
            <IconButton
              aria-label="delete"
              size="large"
              onClick={() => handleClick()}
            >
              <CloseIcon />
            </IconButton>
            </div>{*/}
          <div class="py-4">
            <Typography
              variant="h4"
              className="text-center pt-4 pb-4 d-flex justify-content-center blue-text"
              id="modal-modal-title"
            >
              ¡Ya estás asegurado!
            </Typography>
            <>
              <Typography
                variant="h6"
                align="left"
                className="text-center pt-1 pb-2 d-flex justify-content-center text-black-100"
                id="modal-modal-description"
              >
                Nombre : {client_name} <br />
                Rut: {FormatearRut(rut)} <br />
                Email: {client_email} <br />
                {brand} {model} <br />
                IMEI {maskIMEI(imei)} <br />
                ${value.toLocaleString("es-CL")}/mes en tu boleta
              </Typography>

              <Typography
                variant="body2"
                align="left"
                className="text-black-50 pt-3"
                id="modal-modal-description"
              >
                &nbsp;{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-envelope"
                  viewBox="0 0 16 16"
                >
                  <path
                    className="blue-text"
                    d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"
                  />{" "}
                </svg>{" "}
                &nbsp; Se ha enviado la confirmación a tu correo <br />
                &nbsp;{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-file-earmark-text"
                  viewBox="0 0 16 16"
                >
                  <path
                    className="blue-text"
                    d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5"
                  />
                  <path
                    className="blue-text"
                    d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"
                  />{" "}
                </svg>{" "}
                &nbsp; El certificado de contratación llegará en los próximos 5
                días hábiles <br />
                &nbsp;{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-bookmark-check"
                  viewBox="0 0 16 16"
                >
                  <path
                    className="blue-text"
                    fill-rule="evenodd"
                    d="M10.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0"
                  />
                  <path
                    className="blue-text"
                    d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1z"
                  />
                </svg>{" "}
                &nbsp; La vigencia del seguro es por 18 meses con renovación
                automática por 18 meses más <br />
                &nbsp;{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-telephone"
                  viewBox="0 0 16 16"
                >
                  <path
                    className="blue-text"
                    d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                  />
                </svg>{" "}
                &nbsp; Contactar al 103 en caso que requiera dar de baja su
                seguro <br />
                &nbsp;{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-globe"
                  viewBox="0 0 16 16"
                >
                  <path
                    className="blue-text"
                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m7.5-6.923c-.67.204-1.335.82-1.887 1.855A8 8 0 0 0 5.145 4H7.5zM4.09 4a9.3 9.3 0 0 1 .64-1.539 7 7 0 0 1 .597-.933A7.03 7.03 0 0 0 2.255 4zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a7 7 0 0 0-.656 2.5zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5zM8.5 5v2.5h2.99a12.5 12.5 0 0 0-.337-2.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5zM5.145 12q.208.58.468 1.068c.552 1.035 1.218 1.65 1.887 1.855V12zm.182 2.472a7 7 0 0 1-.597-.933A9.3 9.3 0 0 1 4.09 12H2.255a7 7 0 0 0 3.072 2.472M3.82 11a13.7 13.7 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5zm6.853 3.472A7 7 0 0 0 13.745 12H11.91a9.3 9.3 0 0 1-.64 1.539 7 7 0 0 1-.597.933M8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855q.26-.487.468-1.068zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.7 13.7 0 0 1-.312 2.5m2.802-3.5a7 7 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7 7 0 0 0-3.072-2.472c.218.284.418.598.597.933M10.855 4a8 8 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4z"
                  />
                </svg>{" "}
                &nbsp; Para más detalles acerca del seguro y uso visita{" "}
                <a
                  href={`https://www.entel.cl/seguros/mi-equipo-protegido`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                Mi Equipo Protegido
                </a>{" "}
                <br />
                &nbsp;{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-whatsapp"
                  viewBox="0 0 16 16"
                >
                  <path
                    className="blue-text"
                    d="M13.601 2.326A7.85 7.85 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.9 7.9 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.9 7.9 0 0 0 13.6 2.326zM7.994 14.521a6.6 6.6 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.56 6.56 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592m3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.73.73 0 0 0-.529.247c-.182.198-.691.677-.691 1.654s.71 1.916.81 2.049c.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232"
                  />
                </svg>{" "}
                &nbsp; Contacta a soporte técnico en {" "}
                <a
                  href={`https://wa.me/56998763942?text=Hola%20Quiero%20consultar%20precios`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  +56 998763942
                </a>{" "}
                <br />
              </Typography>
            </>
          </div>
        </Box>
        {/* -------------------- END BOX -------------------- */}
      </Modal>
      {/* -------------------- END MODAL -------------------- */}
    </div>
  );
}

export default InsuranceModal;
