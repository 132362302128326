import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Parser from "html-react-parser";
import InsuranceModal from "./insuranceModal.js";
import { useCookies } from "react-cookie";
import {
  Grid,
  Typography,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Checkbox,
} from "@mui/material";
import { axiosPost } from "../../common/services/axios/apiCalls.js";
import AxiosError from "../../common/components/modals/axiosErrorModal.js";
import { useAuth } from "../../context/authContext.js";
import axios from "axios";

function ContactChannelModal({
  formDataClient,
  imei,
  brand,
  client_name,
  model,
  rut,
  client_email,
  cookieToken,
  cookieSession,
  value
}) {
  const navigate = useNavigate();
  const { cookies, setCookie, removeCookie } = useAuth();

  /** CONST */
  let [modalVisible, setModalVisible] = useState(false);
  let [option, setOption] = useState({});
  const [selectedValue, setSelectedValue] = useState(""); // State to track selected value2
  const [open, setOpen] = React.useState(modalVisible);
  const [channelOptions, setChannelOptions] = useState([]);
  const handleClose = () => setOpen(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  /** MESSAGE STATUS */
  const [status, setStatusError] = useState(null);
  const [messageError, setMessageError] = useState(null);
  const [showErrorModal, setShowErrorModal] = useState(false);

  /** Funciones para manejar modals de error y success */
  const handleCloseErrorModal = () => {
    setShowErrorModal(false);
    setStatusError(null);
    navigate("/logout");
  };

  const handleChange = (event) => {
    setSelectedValue(event.target.value2);
  };

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do nothing, just return the response
      return response;
    },
    function (error) {
      // Any status codes that fall outside the range of 2xx cause this function to trigger
      // Centralized error handling
      const { response } = error;
      if (response) {
        const status = response.status;
        if (status === 401) {
          navigate("/logout");
        } else if (status === 403 || status === 404) {
          setMessageError(
            "Ha ocurrido un error al informar esta venta, por favor vuelva a intentar."
          );
          setStatusError(status);
          setShowErrorModal(true);
        } else {
          console.debugor("Codigo de respuesta:", status);
        }
      } else {
        console.debugor("Network error:", error.message);
      }
      return Promise.reject(error);
    }
  );

  // Crear ticket
  const postTicket = async (data) => {
    const token = cookieToken;
    const url = "/ticket/postTicket";

    try {
      const response = await axiosPost(url, token, data); // Using axiosPost as before

      if (response.status.code === 200) {
        return response.data;
      } else {
        // Handle other status codes here if needed
        console.debugor("Unhandled response status code:", response.status);
        return null;
      }
    } catch (error) {
      // Centralized error handling for network errors
      setMessageError(
        "Ha ocurrido un error, por favor vuelva a intentar."
      );
      setStatusError(error.response ? error.response.status : 500);
      setShowErrorModal(true);
      return null;
    }
  };

  async function handleClick() {
    const arrayData = {
      ...formDataClient,
      //channel: cookies["login-kiwox-cookies-login"].channel,
    };

    if (
      !buttonClicked &&
     //checked1 === true &&
     // checked2 === true &&
     // checked3 === true &&
     // checked4 === true &&
      checkedAll === true
    ) {
      setErroMessageTyC("");
      setButtonClicked(true); // Marking button as clicked
      const ticketData = await postTicket({ token: cookieToken, email: client_email, session: cookieSession });
      if (ticketData) {
     setOption(ticketData);
      setModalVisible(true);
      }
    }
    if (
     // ((checked1 === false) |
      //  (checked2 === false) |
      //  (checked3 === false) |
      //  (checked4 === false) &&
        checkedAll === false
    ) {
      setErroMessageTyC("Debe aceptar todo para finalizar");
    }
  }
  // Funciones para el html de terminos y condiciones
  const [TyCData, setTyCData] = useState('');
  const [value2, setValue] = React.useState("1");

  const fetchTermsConditionData = async () => {
    const token = cookies["login-kiwox-cookies-token"];
    const url = "/parameter/get";
    const name = "terminos_condiciones";

    const response = await axiosPost(url, token, { name });
    if (response.status.code === 200) {
      setTyCData(response.data);
    } else if (response.status.code === 401) {
      console.debug("Token not valid!");
      navigate("/logout");
    } else {
      console.debug(response)
    }
  };
  useEffect(() => {
    fetchTermsConditionData();
  }, []);

  // Funciones de checkbox
  const [checked1, setChecked1] = React.useState(false);
  const [checked2, setChecked2] = React.useState(false);
  const [checked3, setChecked3] = React.useState(false);
  const [checked4, setChecked4] = React.useState(false);
  const [checkedAll, setCheckedAll] = React.useState(false);
  const [errorMessageTyC, setErroMessageTyC] = useState("");

  const handleCheckbox1 = (event) => {
    const isChecked = event.target.checked;
    setChecked1(isChecked);
    setCheckedAll(isChecked && checked2 && checked3 && checked4);
  };

  const handleCheckbox2 = (event) => {
    const isChecked = event.target.checked;
    setChecked2(isChecked);
    setCheckedAll(isChecked && checked1 && checked3 && checked4);
  };

  const handleCheckbox3 = (event) => {
    const isChecked = event.target.checked;
    setChecked3(isChecked);
    setCheckedAll(isChecked && checked1 && checked2 && checked4);
  };

  const handleCheckbox4 = (event) => {
    const isChecked = event.target.checked;
    setChecked4(isChecked);
    setCheckedAll(isChecked && checked1 && checked2 && checked3);
  };

  const handleCheckboxAll = (event) => {
    const isChecked = event.target.checked;
    setCheckedAll(isChecked);
    setChecked1(isChecked);
    setChecked2(isChecked);
    setChecked3(isChecked);
    setChecked4(isChecked);
  };

  return (
    <div className="col-12">
      <Grid
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="d-flex justify-content-center my-5">
          <h3 className="fw-light blue-text">Términos y condiciones</h3>
        </div>
        <div className="col-12 d-flex justify-content-center">
          <div className="col-lg-6 col-md-9 col-sm-10 col-11 row justify-content-center">
            {/* ----------- INICIO TÉRMINOS Y CONDICIONES ----------- */}
            <div className="align-start">
              <div className="col-12 mt-1 scroll-container text-secondary">
                {Parser(TyCData ? TyCData : "")}
              </div>
              <div className="justify-content-start">
                <p className="m-3 ps-3">Términos y condiciones de condiciones generales y particulares.</p>
                <p className="m-3 ps-3">Autorizar cobro de la prima del seguro en la boleta Entel.</p>
                <p className="m-3 ps-3">Autorizar el tratamiento y almacenamiento de datos personales proporcionados por cliente.</p>
                <p className="m-3 ps-3">Declarar que acepta voluntariamente la contratación del seguro.</p>
              </div>{/*
              <div className=" d-flex justify-content-start">
                <FormControlLabel
                  control={
                    <Checkbox
                      // disabled={wspCheckValue}
                      checked={checked2}
                      onChange={handleCheckbox2}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Autorizar cobro de la prima del seguro en la boleta Entel."
                />
              </div>
              <div className=" d-flex justify-content-start">
                <FormControlLabel
                  control={
                    <Checkbox
                      // disabled={wspCheckValue}
                      checked={checked3}
                      onChange={handleCheckbox3}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Autorizar el tratamiento y almacenamiento de datos personales proporcionados por el cliente."
                />
              </div>
              <div className=" d-flex justify-content-start">
                <FormControlLabel
                  control={
                    <Checkbox
                      // disabled={wspCheckValue}
                      checked={checked4}
                      onChange={handleCheckbox4}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Declarar que acepta voluntariamente la contratación del seguro."
                />
                </div> */}
              <div className="d-flex justify-content-start">
                <FormControlLabel
                  control={
                    <Checkbox
                      // disabled={wspCheckValue}
                      checked={checkedAll}
                      onChange={handleCheckboxAll}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Acepto los términos y condiciones anteriormente mencionados."
                />
              </div>
            </div>
          <div className="col-12">
            <div className=" d-flex justify-content-center">
              <Button
                className="btn-principal mt-5 col-md-5 col-sm-7 col-9"
                variant="outlined"
                onClick={() => handleClick()}
                disabled={buttonClicked}
              >
                {buttonClicked ? "Enviando respuesta" : "Continuar"}
              </Button>
            </div>
            <label className="col-12  my-2 justify-content-center m-auto p-auto">
              {errorMessageTyC && (
                <Typography
                  variant="body2"
                  className="red-cross col-12 text-center"
                >
                  {errorMessageTyC}
                </Typography>
              )}
            </label>
            </div>
          </div>
          {modalVisible && (
            <InsuranceModal
              modalVisible={modalVisible}
              option={option}
              imei={imei}
              brand={brand}
              client_name={client_name}
              model={model}
              rut={rut}
              client_email={client_email}
              value={value}
            />
          )}
          <AxiosError
            open={showErrorModal}
            onClose={handleCloseErrorModal}
            status={status}
            message={messageError}
          />
        </div>
      </Grid>
    </div>
  );
}

export default ContactChannelModal;
