import React, { useEffect } from "react";
import {Box, Container, Typography} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';

function NotFound({ message }) {
  /** COOKIES */
  const [cookies, setCookie, removeCookie] = useCookies(['login-kiwox-cookies']);

  const navigate = useNavigate();
  useEffect(() => {
    if(!cookies['login-kiwox-cookies-redirect']){
      localStorage.clear("userData");
      localStorage.clear("userToken");
      localStorage.clear("userPrivileges");
      navigate("/");
    }
  }, []);

  return (
    <div className="bg-login">
      <Container className="d-flex justify-content-center pt-5" component="main">
        <Box className="kiwox-panel shadow d-flex flex-column align-items-center p-4">
        <div className="d-flex justify-content-end">
          <div className="pb-4">
              <img src={'/'} alt="report-logo" />
            </div>
            <div>
              <Typography className="center-elements" variant="body1">{message}</Typography>
            </div>
          </div>
        </Box>
      </Container>
     
    </div>
  );
}

export default NotFound;