import React from 'react';
//import { useAuth } from "../../../context/authContext";
import { useNavigate } from "react-router-dom";
import { useCookies } from 'react-cookie';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useHistory } from 'react-router-dom';


function MenuBar({ useNavbar, useClient }) {
  /** EXTERNAL RESOURCES */
  const LogoNavbar = `navbar-logo.png`;

  /** COOKIES */
  const [cookies, setCookie, removeCookie] = useCookies(['login-kiwox-cookies']);

  const pages = JSON.parse(localStorage.getItem("userPrivileges"));
  const settings = ['Cerrar sesión'];

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
 // const { setUser } = useAuth();

  const user = JSON.parse(localStorage.getItem("userName"));

  const navigate = useNavigate();
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    navigate('/logout')
  }

  const generateMenuItems = (menuItems) => {
    return menuItems.map((item) => {
      if (item.submenu.length > 0 && item.menu === 1) {
        return (
          <Nav expand="md" key={item.title}>
            <NavDropdown title={item.title} anchorEl={anchorElSubmenu[item.title]}
              open={Boolean(anchorElSubmenu[item.title])} onClose={() => handleCloseSubMenu(item.title)}>
              {item.submenu.map((subitem) => (
                <NavDropdown.Item key={subitem.title} onClick={() => navigate(`/${subitem.uri}`)}>
                  <span class="d-flex">{subitem.title}</span>
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          </Nav>
        );
      } else if (item.menu === 1) {
        return (
          <Nav.Link key={item.title} onClick={() => navigate(`/${item.uri}`)}>
            <span>{item.title}</span>
          </Nav.Link>
        );
      }
    });
  };

  const [anchorElSubmenu, setAnchorElSubmenu] = React.useState({});

  const handleOpenSubMenu = (event, submenuName) => {
    setAnchorElSubmenu((prev) => ({
      ...prev,
      [submenuName]: event.currentTarget,
    }));
  };

  const handleCloseSubMenu = (submenuName) => {
    setAnchorElSubmenu((prev) => ({
      ...prev,
      [submenuName]: null,
    }));
  };

  return (
    <Navbar className="navbar shadow" expand="md">
      <div className="container-xxl">
        <Navbar.Brand className='row justify-content-start'>
          <div className='col'><img src={LogoNavbar} alt="Logo" /></div>
          <div className='col blue-text my-auto'>
            <span className='fs-5nav-title'>
              Oferta de seguro
            </span>
          </div>
        </Navbar.Brand>
        {(useNavbar || useClient) &&
          <Navbar.Toggle className='blue-text ' aria-controls="navbarScroll" />
        }
        {/* -------------------- START NAVBAR COLLAPSE -------------------- */}
        <Navbar.Collapse className='justify-content-end' id="navbarScroll">
          {useNavbar &&
            <Nav className="mr-auto my-2 my-lg-0" navbarScroll>
              {/* -------------------- START MENU ITEMS -------------------- 
              <Nav.Link className='nav-link px-3' key="forms" onClick={() => { navigate(`/ofertaseguro`); window.location.reload(); }}>
                <span>Nueva venta</span>
              </Nav.Link>
             -------------------- END MENU ITEMS -------------------- */}
            </Nav>
          }
          {/* -------------------- START USER -------------------- 
          {useClient &&
            <Box className="ms-3" sx={{ flexGrow: 0 }}>
              <Tooltip title={user == null ? "no user data" : user}>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt={user} src={user}>
                    {user && user.charAt(0).toUpperCase()}
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu sx={{ mt: '45px' }} id="menu-appbar"
                anchorEl={anchorElUser} anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
                keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right', }}
                open={Boolean(anchorElUser)} onClose={handleCloseUserMenu}>
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleLogout}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          }
          {/* -------------------- END USER -------------------- */}
        </Navbar.Collapse>
        {/* -------------------- END NAVBAR COLLAPSE -------------------- */}
      </div>
    </Navbar>
  );

}
export default MenuBar;