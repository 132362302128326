import { Box, Button, Container, Grid, Modal, Typography } from "@mui/material";
import React from "react";
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';

function AxiosError({ open, onClose, status = null, message = null }) {
    const handleClose = (event, reason) => {
        if (reason !== "backdropClick") {
            onClose(event, reason);
        }
    };

    return (
        <Modal open={open} onClose={handleClose} disableEscapeKeyDown={true}>
            <Box className="box-modal box-modal-md">
                <div className="box-modal-container">
                    <Button className="exit-btn" variant="outlined" onClick={handleClose}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                            <path d="M3.854 3.146a.5.5 0 0 1 .708 0L8 7.293l3.438-3.437a.5.5 0 0 1 .708.708L8.707 8l3.439 3.437a.5.5 0 0 1-.708.708L8 8.707l-3.438 3.438a.5.5 0 0 1-.708-.708L7.293 8 3.854 4.562a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </Button>
                </div>
                <div className="mx-4 mb-4">
                    <div className="d-flex justify-content-center text-center pb-3">
                        <ErrorTwoToneIcon className="icon-grave me-2" />
                    </div>
                    <div className="d-flex justify-content-center text-center pb-3">
                        <Typography className="fs-5" variant="subtitle1">Se ha producido un error al realizar su solicitud.</Typography>
                    </div>
                    <div className="d-flex justify-content-center text-black-50">
                        <Grid container>
                            {/* Primera Fila */}
                            <Grid container item className="d-flex justify-content-center">
                                <Grid item>
                                    {status !== null && <Typography className="fw-light" variant="subtitle2">Código {status}</Typography>}
                                </Grid>
                            </Grid>

                            {/* Segunda Fila */}
                            <Grid container item className="d-flex justify-content-center">
                                <Grid item>
                                    {message !== null && <Typography className="fw-light" variant="subtitle2">{message}</Typography>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Box>
        </Modal>
    );
}

export default AxiosError;
