import React, { createContext, useState, useContext } from "react";
import { useCookies } from 'react-cookie';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  /** COOKIES */
  const [cookies, setCookie, removeCookie] = useCookies(['login-kiwox-cookies']);

  /** LOCAL STORAGE */
  const userName = JSON.parse(localStorage.getItem("userName"));
  const userToken = JSON.parse(localStorage.getItem("userToken"));
  
  const initialUser = userName ? userName : null;
  const initialToken = userToken ? userToken : null

  const [user, setUser] = useState(initialUser);
  const [token, setToken] = useState(initialToken);

  return (
    <AuthContext.Provider value={{ user, setUser, token, setToken, cookies, setCookie, removeCookie}}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
